import React, { useState } from "react"
import styled from "styled-components"
import ProductCard from "../ProductCard"
import PrimaryButton from "../Button/PrimaryButton"
import { ExploreMore } from "../Section"

const ProductGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 10px -10px;
`
const ProductItemGrid = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 768px) {
    max-width: 50%;
    flex: 0 0 50%;
  }
`

const ProductCardList = ({ data, layout = "", noLimit, location }) => {
  const [limit, setLimit] = useState(6)

  if (noLimit) {
    return (
      <ProductGrid>
        {data.map((prodcut, i) => (
          <ProductItemGrid>
            <ProductCard data={prodcut} key={i} />
          </ProductItemGrid>
        ))}
      </ProductGrid>
    )
  }

  return (
    <div>
      <ProductGrid>
        {data.map((prodcut, i) => {
          if (i < limit) {
            return (
              <ProductItemGrid key={i}>
                <ProductCard data={prodcut} />
              </ProductItemGrid>
            )
          }
        })}
      </ProductGrid>
      {layout === "" && limit < data.length && (
        <ExploreMore>
          <button onClick={() => setLimit(limit + 6)} className="btn-lg">
            <PrimaryButton text="Load More" />
          </button>
        </ExploreMore>
      )}
    </div>
  )
}

export default ProductCardList
