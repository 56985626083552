import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, SectionTitle } from "../components/Section"
import {
  HeroSection,
  HeroCover,
  LeftHero,
  RightHero,
  HeroPageTitle,
  HeroDesc,
  HeroFormWrap,
  HeroFormTitle,
  HeroFormBody,
} from "../components/Hero"
import TabProductCardList from "../components/TabProductCardList"
import CallToAction from "../components/CallToAction"
import QuickContactForm from "../components/QuickContactForm"

const OtherBuildingsPage = pageData => {
  const productData = pageData.data.allContentfulProduct.edges.filter(
    edge =>
      edge.node.productCategory &&
      edge.node.productCategory.name &&
      edge.node.productRoofType &&
      edge.node.productRoofType.name
  )
  let otherProductData = []
  productData.forEach(function(item, index) {
    if (
      item.node.productCategory.name !== "Carports" &&
      item.node.productCategory.name !== "Garages" &&
      item.node.productCategory.name !== "Barns" &&
      item.node.productCategory.name !== "RV Covers" &&
      item.node.productCategory.name !== "Carolina Barns" &&
      item.node.productCategory.name !== "Seneca Barns"
    ) {
      otherProductData.push(item)
    }
  })
  return (
    <Layout location={pageData.location}>
      <SEO
        title="Custom Steel Building Manufacturer - ProBuilt Steel Buildings"
        description="ProBuilt Steel is a leading manufacturers of durable, customizable steel buildings. Get expert guidance and build with confidence. Explore our wide range of options and get a free quote."
      />
      <HeroSection>
        <div className="container">
          <HeroCover>
            <LeftHero>
              <HeroPageTitle>Steel Buildings</HeroPageTitle>
              <HeroDesc>
                <p>
                  No home or business is complete without a garage. It’s the one
                  place where you can store your car, gardening equipment,
                  holiday decorations, and everything else that you don’t want
                  to cram your living area. If you don’t have a garage or wish
                  there was extra space, consider a metal garage. It will serve
                  you well, as other owners would testify. ProBuilt steel
                  garages are engineered precisely to be highly usable,
                  cost-efficient, durable, convenient, and energy-efficient.
                </p>
              </HeroDesc>
            </LeftHero>
            <RightHero>
              <HeroFormWrap>
                <HeroFormTitle>Get In Touch</HeroFormTitle>
                <HeroFormBody>
                  <QuickContactForm
                    location={pageData.location}
                    formName="Steel Building Contact Form"
                  />
                </HeroFormBody>
              </HeroFormWrap>
            </RightHero>
          </HeroCover>
        </div>
      </HeroSection>

      <Section pt="95px" pb="90px" bg="#F4FBFF">
      	<SectionTitle>Explore Steel Buildings</SectionTitle>
      	<div className="container steel-building-product">
      		<TabProductCardList data={otherProductData} category="Buildings" location={pageData.location} />
      	</div>
      </Section>
      <CallToAction />
    </Layout>
  )
}

export default OtherBuildingsPage

export const pageQuery = graphql`
  query OtherBuildingsPageQuery {
    allContentfulProduct(sort: { fields: skuNumber }) {
      edges {
        node {
          id
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              base64
              sizes
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          productServices {
            spaceId
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
          numberOfSale
          canBeUsedAs {
            content
          }
        }
      }
    }
  }
`
